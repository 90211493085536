import React from "react";
import { View, ImageBackground, TouchableWithoutFeedback } from "react-native";
import { H3, P, H1 } from "../common/ZCoffeeText";
import { R } from "../assets/Assets";

const POLLING_INTERVAL = 1000;

export default class CoffeeTemperatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tempWater: 0,
      tempHeater: 0,
      coffeeCounter: 0,
      touch1: false,
      touch2: false
    };

    setInterval(() => {
      this.refreshData();
    }, POLLING_INTERVAL);
  }

  componentWillMount() {
    this.refreshData();
  }

  refreshData() {
    fetch(R.urls.getCoffeeStatusUrl, {
      method: "GET",
      "Content-Type": "application/json"
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        if (!json.result) {
          console.error(json);
        } else {
          this.setState({
            tempWater: json.data.temp_1,
            tempHeater: json.data.temp_2,
            coffeeCounter: json.data.coffe_counter
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  checkAndReset() {
    if (this.state.touch1 && this.state.touch2) {
      this.setState({ touch1: false, touch2: false });
      fetch(R.urls.resetCoffee, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          coffee_counter: 0
        })
      })
        .then(res => {
          return res.json();
        })
        .then(json => {
          if (!json.result) throw new Error(json);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.setState({ touch1: true });
          }}
        >
          <View style={styles.temp}>
            <H3>
              {this.state.tempWater}
              °C
            </H3>
            <P>
              Temp.
              {"\n"}
              WATER
            </P>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onLongPress={() => {
            this.checkAndReset();
          }}
        >
          <ImageBackground
            style={styles.coffee}
            source={require("../assets/chicco.png")}
          >
            <H1>{this.state.coffeeCounter}</H1>
          </ImageBackground>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onPress={() => {
            if (this.state.touch1) this.setState({ touch2: true });
            else this.setState({ touch1: false });
          }}
        >
          <View style={styles.temp}>
            <H3>
              {this.state.tempHeater}
              °C
            </H3>
            <P>
              Temp.
              {"\n"}
              HEATER
            </P>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    zIndex: 99999999,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  temp: {
    width: "25%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  coffee: {
    width: 120,
    height: 120,
    marginLeft: 20,
    marginRight: 20,
    justifyContent: "center",
    alignItems: "center"
  }
};
