import React, { Component } from "react";
import {
  StyleSheet,
  TextInput,
  View,
  TouchableOpacity,
  Image
} from "react-native";
import { R } from "../assets/Assets";
import AppHeader from "../components/AppHeader";
import { H2, P, H1, A } from "../common/ZCoffeeText";
import CoffeeTemperatures from "../components/CoffeeTemperatures";
import CoffeeGif from "../components/CoffeeGif";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../components/imported/react-alert-template-basic";
import firebase from "firebase";

const options = {
  position: "bottom center",
  timeout: 6000,
  offset: "90px",
  transition: "scale",
  type: "info"
};

export default class App extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      coffeeRunning: false,
      enableBtn: true,
      newsletter: false,
      userEmail: "",
      consentPrivacy: false,
      finished: false
    };
    this.windowHeight = window.innerHeight;
  }

  componentWillMount() {
    const config = {
      databaseURL: "https://zcoffee-newsletters.firebaseio.com",
      projectId: "zcoffee-newsletters"
    };
    firebase.initializeApp(config);
  }

  startMakingCoffee() {
    this.setState({ enableBtn: false });
    // removing hashtag from the hash to send to the post server
    const codeToSend = window.location.hash.substr(1);
    fetch(R.urls.makeCoffee, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ code: codeToSend })
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        if (!json.result) {
          this.alert.show("You can have only one coffee at a time!");
          this.setState({
            coffeeRunning: false,
            enableBtn: true,
            newsletter: true
          });
          return;
        } else {
          this.setLoadingCoffee();
        }
      })
      .catch(err => {
        console.error(err);
        this.alert.show("The coffee machine is stuck!");
        this.setState({ coffeeRunning: false, enableBtn: true });
      });
  }

  setLoadingCoffee() {
    this.setState(
      pv => {
        return {
          coffeeRunning: true,
          enableBtn: false
        };
      },
      () => {
        setTimeout(() => {
          this.setState({
            coffeeRunning: false,
            enableBtn: true,
            newsletter: true
          });
        }, 10000);
      }
    );
  }
  acceptPrivacyPolicy() {
    this.setState(pv => {
      return {
        consentPrivacy: !pv.consentPrivacy
      };
    });
  }

  sendUserEmail() {
    const emailValue = this.state.userEmail;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(emailValue).toLowerCase())) {
      firebase
        .database()
        .ref("/")
        .push({
          emailValue
        })
        .then(data => {
          this.setState({ finished: true });
        })
        .catch(error => {
          this.alert.show("Something went wrong");
          console.error(JSON.stringify(error));
        });
    } else {
      this.alert.show("Check your email!");
    }
  }

  render() {
    const styles = this.innerHeight > 660 ? stylesBig : stylesSmall;

    // Per chi leggerà questo codice in futuro: scusate ero di fretta... XD

    // banner centrale per il caffe e la relativa gif
    const coffeeBanner = (
      <View style={styles.container}>
        <View style={styles.header}>
          <AppHeader />
        </View>
        <View style={styles.content}>
          <H2>We turn coffee into Magic</H2>
          <CoffeeTemperatures />
          <CoffeeGif animated={this.state.coffeeRunning} />
        </View>
        <View style={styles.bottom}>
          <TouchableOpacity
            style={!this.state.enableBtn ? styles.btnDisabled : styles.btn}
            onPress={() => this.startMakingCoffee()}
            disabled={!this.state.enableBtn}
          >
            <A>COFFEE</A>
          </TouchableOpacity>
        </View>
      </View>
    );

    // banner da visualizzare per iscriversi alla newsletter
    const newsletters = (
      <View style={styles.nContainer}>
        <View style={styles.header}>
          <AppHeader />
          <View style={styles.newsletterContainer}>
            <Image
              source={require("../assets/contact.png")}
              style={{ width: 200, height: 200 }}
            />
            <H2>
              Enjoyed our coffee?
              {"\n"}
              Let's keep in touch!
            </H2>
            <TextInput
              placeholder="Your email address"
              style={styles.input}
              onChangeText={value => {
                this.setState({ userEmail: value });
              }}
              value={this.state.userEmail}
            />
            <View style={{ margin: 20, flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  this.acceptPrivacyPolicy();
                }}
              >
                <Image
                  source={
                    !this.state.consentPrivacy
                      ? require("../assets/off.png")
                      : require("../assets/on.png")
                  }
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
              </TouchableOpacity>
              <P>
                I do allow you to store my personal data and using it only to
                manage my request as specified by our{" "}
                <a
                  href="http://www.zepping.it/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </a>{" "}
                and for future contacts.
              </P>
            </View>

            <TouchableOpacity
              onPress={() => {
                this.sendUserEmail();
              }}
              style={styles.btn}
              disabled={!this.state.consentPrivacy}
            >
              <A>CONFIRM</A>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );

    const thankYou = (
      <View style={styles.thanks}>
        <View style={styles.header}>
          <AppHeader />
          <View style={styles.thanksContainer}>
            <Image
              source={require("../assets/yeah.png")}
              style={{ width: 150, height: 150 }}
            />
            <H2>Thank you!</H2>
            <P>
              Take a look to <a href="http://www.zepping.it">our website!</a>
            </P>
          </View>
        </View>
      </View>
    );

    return (
      <AlertProvider
        template={AlertTemplate}
        {...options}
        ref={a => (this.alert = a)}
        style={{ backgroundColor: "white" }}
      >
        {this.state.newsletter
          ? this.state.finished
            ? thankYou
            : newsletters
          : coffeeBanner}
      </AlertProvider>
    );
  }
}

const stylesBig = StyleSheet.create({
  container: {
    height: "100%",
    flex: 1,
    justifyContent: "space-between"
  },
  header: {
    flex: 0.22
  },
  content: {
    flex: 0.6
  },
  bottom: {
    height: 50,
    marginTop: 100,
    backgroundColor: R.colors.ll_blue,
    justifyContent: "center",
    alignItems: "center"
  },
  btn: {
    backgroundColor: R.colors.blue,
    borderRadius: 128,
    width: 257,
    height: 55,
    alignItems: "center",
    justifyContent: "center"
  },
  btnDisabled: {
    backgroundColor: R.colors.l_blue_op,
    borderRadius: 128,
    width: 257,
    height: 55,
    alignItems: "center",
    justifyContent: "center"
  },
  newsletterContainer: {
    alignSelf: "center",
    width: "55%",
    justifyContent: "center",
    flex: 1,
    alignItems: "center"
  },
  thanksContainer: {
    alignSelf: "center",
    width: "55%",
    justifyContent: "center",
    flex: 1,
    alignItems: "center"
  },
  nContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }
});

const stylesSmall = StyleSheet.create({
  container: {
    height: "100%",
    flex: 1,
    justifyContent: "space-between"
  },
  bottom: {
    minHeight: 100,
    maxHeight: 400,
    backgroundColor: R.colors.ll_blue,
    justifyContent: "center",
    alignItems: "center"
  },
  btn: {
    backgroundColor: R.colors.blue,
    borderRadius: 128,
    width: 257,
    height: 55,
    alignItems: "center",
    justifyContent: "center"
  },
  btnDisabled: {
    backgroundColor: R.colors.l_blue_op,
    borderRadius: 128,
    width: 257,
    height: 55,
    alignItems: "center",
    justifyContent: "center"
  },
  input: {
    alignSelf: "center",
    borderWidth: 1,
    borderRadius: 10,
    borderColor: R.colors.blue,
    padding: 10,
    fontSize: 18,
    margin: 20,
    width: "100%",
    textAlign: "center"
  },
  newsletterContainer: {
    alignSelf: "center",
    maxWidth: "100%",
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
    paddingTop: 50,
    paddingBottom: 30,
    margin: 10
  },
  nContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "space-between"
  },
  thanks: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "space-between"
  },
  thanksContainer: {
    alignSelf: "center",
    width: "55%",
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
    margin: 100
  }
});
