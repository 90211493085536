import React from "react";
import { View, Image, StyleSheet } from "react-native";

export default class CoffeeGif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animated: this.props.animated
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({ animated: this.props.animated });
    }
  }

  componentWillMount() {
    this.gif = require("../assets/coffeeGif.gif");
    this.frame = require("../assets/frame.gif");
    this.setAnimatedOrNotImage();
  }

  setAnimatedOrNotImage() {
    this.setState({
      animated: this.props.animated
    });
  }

  render() {
    return (
      <View style={styles.container}>
        {!this.state.animated ? (
          <Image
            style={styles.image}
            source={this.frame}
            resizeMode="contain"
          />
        ) : (
          <Image style={styles.image} source={this.gif} resizeMode="contain" />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    width: 300,
    height: 215
  }
});
