import React from "react";
import {
  View,
  ImageBackground,
  TextInput,
  TouchableOpacity
} from "react-native";
import { P, H1, A, H2 } from "../common/ZCoffeeText";
import { R } from "../assets/Assets";
import QRCode from "qrcode-react";
import AppHeader from "../components/AppHeader";

export default class QrCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordRequired: true,
      currentCode: ""
    };
    setInterval(() => {
      this.fetchCode();
    }, 1000);
  }

  sendPassword() {
    this.setState({ passwordRequired: false });
  }

  fetchCode() {
    if (!this.state.passwordRequired) {
      fetch(R.urls.getCoffeeSecretUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          password: this.state.password
        })
      })
        .then(res => {
          return res.json();
        })
        .then(json => {
          if (!json.result) throw new Error(json);
          this.setState({
            currentCode: "https://zcoffee.thingscloud.it/#" + json.data.code
          });
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
  render() {
    return (
      <View style={styles.container}>
        <ImageBackground resizeMode={"cover"} style={styles.background}>
          <View style={styles.header}>
            <AppHeader />
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.content}>
              {this.state.passwordRequired ? (
                <View>
                  <H2>A password is required!</H2>
                  <TextInput
                    underlineColorAndroid="red"
                    placeholder="Here the super secret password :D"
                    style={styles.input}
                    secureTextEntry
                    onChangeText={value => {
                      this.setState({ password: value });
                    }}
                    value={this.state.password}
                  />
                  <TouchableOpacity
                    onPress={() => {
                      this.sendPassword();
                    }}
                    style={styles.btn}
                  >
                    <A>CONFIRM</A>
                  </TouchableOpacity>
                </View>
              ) : this.state.currentCode.length > 0 ? (
                <View>
                  <QRCode
                    size={230}
                    value={this.state.currentCode}
                    style={styles.qrcode}
                    fgColor={R.colors.l_blue}
                    logo={require("../assets/tazzina.png")}
                  />
                  <P style={styles.text}>
                    Scan the QR code {"\n"} to get another coffee!
                  </P>
                </View>
              ) : (
                <P>Wrong password?</P>
              )}
            </View>
          </View>
        </ImageBackground>
      </View>
    );
  }
}
const styles = {
  container: {
    flex: 1,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  background: {
    flex: 1,
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: R.colors.ll_blue
  },
  header: {
    width: "100%",
    backgroundColor: R.colors.white
  },
  contentContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 30,
    padding: 30
  },
  text: {
    marginTop: 15
  },
  btn: {
    backgroundColor: R.colors.blue,
    borderRadius: 128,
    width: 257,
    height: 55,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center"
  },
  input: {
    alignSelf: "center",
    borderWidth: 1,
    borderRadius: 10,
    borderColor: R.colors.blue,
    padding: 10,
    fontSize: 18,
    margin: 20,
    width: "100%"
  }
};
