export const R = Object.freeze({
  colors: {
    blue: "rgb(71,145,203)",
    brown: "rgb(63,34,16)",
    white: "white",
    l_blue: "rgb(71,145,203)",
    l_blue_op: "rgba(71,145,203, 0.4)",
    ll_blue: "rgb(166,194,217)"
  },
  urls: {
    getCoffeeStatusUrl: "https://zcoffee.thingscloud.it/api/coffee_status",
    makeCoffee: "https://zcoffee.thingscloud.it/api/make_coffee",
    resetCoffee: "https://zcoffee.thingscloud.it/api/set_coffee_counter",
    getCoffeeSecretUrl: "https://zcoffee.thingscloud.it/api/get_last_secret"
  }
});
