import React from "react";
import { View, Image, StyleSheet } from "react-native";
import { R } from "../assets/Assets";

export default class AppHeader extends React.Component {
  render() {
    const styles = window.innerHeight > 660 ? stylesBig : stylesSmall;
    return (
      <View style={styles.container}>
        <Image
          source={require("../assets/logo.png")}
          style={styles.logo}
          resizeMode="contain"
        />
        <View style={styles.bluView}>
          <Image
            source={require("../assets/fasciaBlu.png")}
            style={styles.bluBar}
          />
        </View>
      </View>
    );
  }
}

const stylesBig = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
  logo: {
    width: 300,
    height: 130,
    padding: 10
  },
  bluBar: {
    width: 300,
    height: 35,
    backgroundColor: R.colors.blue,
    alignSelf: "center"
  },
  bluView: {
    backgroundColor: R.colors.blue,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: "100%",
    height: 35
  }
});

const stylesSmall = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    marginTop: 10
  },
  logo: {
    width: 300,
    height: 60,
    marginBottom: 10
  },
  bluBar: {
    width: 300,
    height: 30,
    backgroundColor: R.colors.blue,
    alignSelf: "center"
  },
  bluView: {
    backgroundColor: R.colors.blue,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: "100%",
    height: 35
  }
});
