import React from "react";
import { Text, StyleSheet } from "react-native";
import { R } from "../assets/Assets";

export const P = props => {
  return (
    <Text
      style={StyleSheet.compose(
        styles.text,
        props.style
      )}
    >
      {props.children}
    </Text>
  );
};
export const H1 = props => {
  return <Text style={styles.h1}>{props.children}</Text>;
};
export const H2 = props => {
  return <Text style={styles.h2}>{props.children}</Text>;
};

export const H3 = props => {
  return <Text style={styles.h3}>{props.children}</Text>;
};

export const A = props => {
  return <Text style={styles.btn}>{props.children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: "Montserrat-Light",
    color: R.colors.l_blue,
    textAlign: "center",
    fontSize: 15
  },
  h1: {
    fontFamily: "Montserrat-Bold",
    fontSize: 50,
    color: R.colors.white
  },
  h3: {
    fontFamily: "Montserrat-Light",
    fontSize: 28,
    color: R.colors.l_blue,
    textAlign: "center"
  },
  h2: {
    fontFamily: "Montserrat-Medium",
    fontSize: 24,
    textAlign: "center",
    color: R.colors.brown,
    fontWeight: "600",
    marginTop: 25,
    padding: 5
  },
  btn: {
    fontFamily: "Montserrat-Medium",
    fontSize: 24,
    color: R.colors.white,
    lineHeight: 29
  }
});
